import { ref } from '@vue/composition-api'
import axiosInstances from '@/libs/axios-instances'
import StartDatePassedStatus from '@/common/compositions/common/datePassed'

export default function eventWithItsSessions() {
  const { isDatePassed, checkIsDatePassed } = StartDatePassedStatus()

  const isEventDataReady = ref(false)
  const eventData = ref(null)
  const sessionsList = ref(null)

  const getEventDataWithSessions = eventID => {
    axiosInstances.activities.get(`internalops/prayer-activities/${eventID}`).then(res => {
      eventData.value = res.data.data
      sessionsList.value = res.data.data.sessions
      isEventDataReady.value = true
      checkIsDatePassed(eventData.value.end_time)
    })
  }

  return {
    isEventDataReady,
    eventData,
    sessionsList,
    isDatePassed,
    getEventDataWithSessions,
  }
}
